<script lang="ts">
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import { DataFormatOverrideType, type DataFormatOverrides } from "./types"
  import Input from "@/lib/Input.svelte"
  import { EnumT } from "@shared/schema"
  import DurationInput from "@/lib/DurationInput.svelte"
  import DatetimeInput from "@/lib/DatetimeInput.svelte"
  import { DateTime } from "luxon"
  import { eed } from "@/util"
  import RatingStars from "@/lib/RatingStars.svelte"

  export let value: GenericDataValue<EnumT.DataType.number> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides = {}
  export let autofocus: boolean = false
  export let clearable: boolean = false
  export let disabled: boolean

  const onDateTimeInput = (dtv: DateTime | undefined) => {
    value = dtv?.toSeconds()
  }
  $: dateTimeValue = value != null ? DateTime.fromSeconds(value) : undefined

  const selectOption = (val: unknown) => {
    dataFormatOverrides[DataFormatOverrideType.optionSettings]?.onSelectOption(
      val
    )
  }
</script>

{#if formatSpec.type === EnumT.FormatSpecType.duration}
  <DurationInput
    bind:value
    minimumUnits={formatSpec.minimumUnits ?? EnumT.DurationUnits.seconds}
    on:blur={dataFormatOverrides[DataFormatOverrideType.onBlur]}
    {autofocus}
    {disabled}
  />
{:else if formatSpec.type === EnumT.FormatSpecType.datetime}
  <DatetimeInput
    {clearable}
    includeTime={formatSpec.includeTime}
    bind:value={dateTimeValue}
    on:input={eed(onDateTimeInput)}
    on:blur={dataFormatOverrides[DataFormatOverrideType.onBlur]}
    {autofocus}
    {disabled}
  />
{:else if formatSpec.type === EnumT.FormatSpecType.rating}
  <RatingStars bind:value />
{:else}
  <Input
    type="number"
    {autofocus}
    {disabled}
    bind:value
    fullwidth
    on:blur={dataFormatOverrides[DataFormatOverrideType.onBlur]}
    on:keydown={dataFormatOverrides[DataFormatOverrideType.onKeydown]}
    on:selectOption={eed(selectOption)}
    options={dataFormatOverrides[DataFormatOverrideType.optionSettings]
      ?.options}
  />
{/if}
