<script lang="ts">
  import { ArrowKey } from "@/constants"
  import type { MaybeFocusable, OptionT } from "@/types"
  import { keyPressWrapper } from "@/util"
  import { arithMod } from "@shared/util/index.ts"
  import { createEventDispatcher } from "svelte"

  type T = $$Generic
  export let options: OptionT<T>[]
  export let fullwidth: boolean = false
  export let fullheight: boolean = false
  export let expanded: boolean = false
  export let inline: boolean = false
  export let autofocus: boolean = false
  export let values: T[] = []
  export let expandWhenEmpty: boolean = false
  export const simulateKey = (key: ArrowKey) => {
    onWrapperKeypress(new KeyboardEvent("keydown", { key }))
  }

  const NO_OPTIONS_VALUE = "7ea3ff5c-ae97-4b34-8d02-6fb93ff3dc57"
  const NO_OPTIONS_OPTION: OptionT<T> = {
    label: "No options",
    value: NO_OPTIONS_VALUE as T,
  }

  let listbox: HTMLDivElement | undefined
  let activeOptionIndex: number | undefined = undefined

  const dispatch = createEventDispatcher<{
    selectOption: T
    requestExpansion: undefined
    wrapperKeypress: { originalEvent: KeyboardEvent }
    blur: undefined
  }>()

  const onWrapperKeypress = (ev: KeyboardEvent) => {
    if (ev.key === ArrowKey.up) {
      if (!expanded) {
        dispatch("requestExpansion")
        return
      }
      activeOptionIndex =
        activeOptionIndex === undefined
          ? 0
          : arithMod(activeOptionIndex - 1, options.length)
      ev.stopPropagation()
      ev.preventDefault()
    } else if (ev.key === ArrowKey.down) {
      if (!expanded) {
        dispatch("requestExpansion")
        return
      }
      activeOptionIndex =
        activeOptionIndex === undefined
          ? 0
          : arithMod(activeOptionIndex + 1, options.length)
      ev.stopPropagation()
      ev.preventDefault()
    }
    dispatch("wrapperKeypress", { originalEvent: ev })
  }

  const selectOption = (option: OptionT<T>) => () => {
    if (option.value === NO_OPTIONS_VALUE) {
      return
    }

    dispatch("selectOption", option.value)
  }

  let didAttemptAutofocus = false
  const setDidAttemptAutofocus = (val: boolean) => (didAttemptAutofocus = val)
  const getDidAttemptAutoFocus = () => didAttemptAutofocus

  $: if (activeOptionIndex !== undefined && expanded) {
    if (getDidAttemptAutoFocus() || autofocus) {
      const elem = listbox?.children[activeOptionIndex] as
        | MaybeFocusable
        | undefined
      elem?.focus?.()
    }
    setDidAttemptAutofocus(true)
  }

  $: if (!expanded) setDidAttemptAutofocus(false)

  $: filteredOptions = options.filter(({ value }) => !values.includes(value))
  $: displayedOptions =
    filteredOptions.length > 0 ? filteredOptions : [NO_OPTIONS_OPTION]

  $: canExpand = expandWhenEmpty || options.length > 0
</script>

<div
  class="wrapper"
  class:fullwidth
  class:fullheight
  class:inline
  on:keydown={onWrapperKeypress}
>
  <slot />
  {#if expanded && canExpand}
    <div
      class="suggestions"
      bind:this={listbox}
      role="listbox"
      aria-expanded={expanded}
    >
      {#each displayedOptions as option}
        <div
          role="option"
          class="option"
          class:no-options={option.value === NO_OPTIONS_VALUE}
          tabindex="0"
          on:click={selectOption(option)}
          on:keypress={keyPressWrapper(selectOption(option))}
          aria-selected={values.some((val) => val === option.value)}
        >
          {#if typeof option?.label === "string"}
            <span class="option-label">{option.label}</span>
          {:else if option !== undefined}
            <svelte:component this={option.label} value={option.value} />
          {/if}
        </div>
      {/each}
    </div>
  {/if}
</div>

<style>
  .wrapper {
    position: relative;
    display: inline-block;
  }
  .wrapper.fullwidth {
    width: 100%;
    flex: 1;
    display: flex;
  }
  .wrapper.fullheight {
    height: 100%;
    flex: 1;
    display: flex;
    align-self: stretch;
  }
  .wrapper.inline {
    display: inline-block;
  }
  .suggestions {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 50;
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-bg);
  }
  .option-label {
    margin-left: 4px;
  }
  .selected-option {
    background-color: var(--action-alt);
    color: white;
  }
  .suggestions {
    position: absolute;
    top: 100%;
    margin-top: -1px;
    left: 0;
    right: 0;
    background-color: var(--primary-bg);
    border: 2px solid var(--action-alt);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
    z-index: 9999;
    overflow-y: auto;
  }
  .option {
    background-color: var(--secondary-bg);
    padding: 5px;
    user-select: none;
  }
  .option:focus {
    background-color: var(--primary-bg);
  }
  .option:not(.no-options):hover {
    opacity: 0.7;
  }
</style>
