import type { ColumnType } from "kysely"

export enum AccessType {
  owner = "owner",
  viewer = "viewer",
}

export enum DataType {
  boolean = "boolean",
  number = "number",
  numberArray = "number_array",
  text = "text",
  textArray = "text_array",
}

export enum DefaultColumnBehavior {
  create = "create",
  hide = "hide",
  include = "include",
}

export enum ExtractionStatus {
  degraded = "degraded",
  dispatched = "dispatched",
  failure = "failure",
  success = "success",
}

export enum Field {
  availableAt = "available_at",
  baths = "baths",
  beds = "beds",
  bodyStyle = "body_style",
  cabType = "cab_type",
  certifiedPreowned = "certified_preowned",
  cityMpg = "city_mpg",
  cleanTitle = "clean_title",
  cylinderCount = "cylinder_count",
  dateListed = "date_listed",
  doorCount = "door_count",
  drivetrain = "drivetrain",
  engine = "engine",
  exteriorColor = "exterior_color",
  externalId = "external_id",
  financingEligible = "financing_eligible",
  fuelType = "fuel_type",
  highwayMpg = "highway_mpg",
  homeDelivery = "home_delivery",
  images = "images",
  interiorColor = "interior_color",
  isAvailable = "is_available",
  location = "location",
  make = "make",
  mileage = "mileage",
  model = "model",
  noAccidents = "no_accidents",
  numReviews = "num_reviews",
  oneOwner = "one_owner",
  openRecall = "open_recall",
  personalUse = "personal_use",
  predictedPriceUsd = "predicted_price_usd",
  priceGbp = "price_gbp",
  priceUsd = "price_usd",
  rating = "rating",
  seatCount = "seat_count",
  sellerName = "seller_name",
  sellerPhone = "seller_phone",
  sellerType = "seller_type",
  sellerUrl = "seller_url",
  source = "source",
  sqft = "sqft",
  title = "title",
  trim = "trim",
  url = "url",
  usageStatus = "usage_status",
  year = "year",
}

export enum IdentityProvider {
  auth0 = "auth0",
}

export enum ListingType {
  apartment = "apartment",
  automobile = "automobile",
  furniture = "furniture",
  homestay = "homestay",
  hostel = "hostel",
  house = "house",
}

export enum Source {
  airbnb = "airbnb",
  apartmentsCom = "apartments_com",
  cars = "cars",
  corcoran = "corcoran",
  craigslist = "craigslist",
  genericLink = "generic_link",
  hostelworld = "hostelworld",
  noSource = "no_source",
  realtor = "realtor",
  redfin = "redfin",
  streetEasy = "street_easy",
  trulia = "trulia",
  vrbo = "vrbo",
  wayfair = "wayfair",
  zillow = "zillow",
  zooplaCouk = "zoopla_couk",
}

export enum UserType {
  admin = "admin",
  regular = "regular",
}

export type Generated<T> = T extends ColumnType<infer S, infer I, infer U>
  ? ColumnType<S, I | undefined, U>
  : ColumnType<T, T | undefined, T>

export type Json = JsonValue

export type JsonArray = JsonValue[]

export type JsonObject = {
  [K in string]?: JsonValue
}

export type JsonPrimitive = boolean | number | string | null

export type JsonValue = JsonArray | JsonObject | JsonPrimitive

export type Timestamp = ColumnType<Date, Date | string, Date | string>

export interface CachedFuncCalls {
  created: Generated<Timestamp>
  id: Generated<string>
  serializedFuncCall: Json
  updated: Generated<Timestamp>
  value: Json | null
}

export interface Cells {
  created: Generated<Timestamp>
  id: Generated<string>
  sheetColumnId: string
  sheetRowId: string
  updated: Generated<Timestamp>
  value: Generated<Json>
}

export interface ColumnDefinitions {
  created: Generated<Timestamp>
  dataType: DataType
  field: Field | null
  formatSpec: Json
  generatorSpec: string | null
  id: Generated<string>
  updated: Generated<Timestamp>
}

export interface ExtractedData {
  created: Generated<Timestamp>
  field: Field
  id: Generated<string>
  source: Source
  sourceKey: string
  updated: Generated<Timestamp>
  value: Json | null
}

export interface ExtractionLogs {
  created: Generated<Timestamp>
  extractionStatus: ExtractionStatus
  id: Generated<string>
  log: string | null
  source: Source
  sourceKey: string
  updated: Generated<Timestamp>
}

export interface FieldSettings {
  defaultColumnBehavior: DefaultColumnBehavior
  refName: Field
  sortedOrder: number
}

export interface ProxySettings {
  config: Json
  created: Generated<Timestamp>
  id: Generated<string>
  source: Source
  updated: Generated<Timestamp>
}

export interface SheetColumns {
  columnDefinitionId: string
  created: Generated<Timestamp>
  hidden: Generated<boolean>
  id: Generated<string>
  name: string
  sheetId: string
  sortedOrder: Generated<number>
  updated: Generated<Timestamp>
}

export interface SheetGlobals {
  columnDefinitionId: string
  columnScoped: boolean | null
  created: Generated<Timestamp>
  id: Generated<string>
  sheetId: string
  updated: Generated<Timestamp>
  value: Json | null
  varName: string
}

export interface SheetRows {
  created: Generated<Timestamp>
  id: Generated<string>
  originalInput: string | null
  sheetId: string
  sortedOrder: Generated<number>
  source: Source
  sourceKey: string
  updated: Generated<Timestamp>
}

export interface Sheets {
  created: Generated<Timestamp>
  defaultAccessType: Generated<AccessType>
  id: Generated<string>
  listingType: ListingType | null
  name: string
  updated: Generated<Timestamp>
}

export interface SheetViews {
  created: Generated<Timestamp>
  filters: Json | null
  grouping: Json | null
  id: Generated<string>
  sheetId: string
  updated: Generated<Timestamp>
  userAccountId: string
}

export interface UserAccountLinks {
  created: Generated<Timestamp>
  email: string
  id: Generated<string>
  identityProvider: IdentityProvider
  sub: string
  updated: Generated<Timestamp>
  userAccountId: string
}

export interface UserAccounts {
  created: Generated<Timestamp>
  id: Generated<string>
  name: string
  updated: Generated<Timestamp>
  userType: Generated<UserType>
}

export interface UserAccountSheets {
  accessType: AccessType
  activeSheetViewId: string | null
  created: Generated<Timestamp>
  id: Generated<string>
  sheetId: string
  updated: Generated<Timestamp>
  userAccountId: string
}

export interface DB {
  cachedFuncCalls: CachedFuncCalls
  cells: Cells
  columnDefinitions: ColumnDefinitions
  extractedData: ExtractedData
  extractionLogs: ExtractionLogs
  fieldSettings: FieldSettings
  proxySettings: ProxySettings
  sheetColumns: SheetColumns
  sheetGlobals: SheetGlobals
  sheetRows: SheetRows
  sheets: Sheets
  sheetViews: SheetViews
  userAccountLinks: UserAccountLinks
  userAccounts: UserAccounts
  userAccountSheets: UserAccountSheets
}
