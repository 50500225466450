import { CurrencyCode } from "../schema/currencies.ts"
import { EnumT } from "../schema/index.ts"
import type {
  FunctionDefinition,
  GenericDataValue,
  Nully,
  PartialRecord,
} from "../types.ts"

export type HostelworldRoomTypePreference =
  | "dorm1to4"
  | "dorm5to8"
  | "dorm9+"
  | "private"

const roomTypePreferences: {
  label: string
  value: HostelworldRoomTypePreference
}[] = [
  { label: "Dorm: 1-4 beds", value: "dorm1to4" },
  { label: "Dorm: 5-8 beds", value: "dorm5to8" },
  { label: "Dorm: 9+ beds", value: "dorm9+" },
  { label: "Private room", value: "private" },
]

export type HostelworldDormGenderPreference =
  | "maleOnly"
  | "femaleOnly"
  | "mixed"

const dormGenderPreferences: {
  label: string
  value: HostelworldDormGenderPreference
}[] = [
  { label: "Mixed Dorm", value: "mixed" },
  { label: "Female Dorm", value: "femaleOnly" },
  { label: "Male Dorm", value: "maleOnly" },
]

const sum: FunctionDefinition = {
  name: "Sum",
  async: false,
  args: [
    {
      nullable: true,
      dataType: EnumT.DataType.number,
      variadric: true,
    },
  ],
  dataType: EnumT.DataType.number,
  formatSpec: { type: EnumT.FormatSpecType.none },
  handler: (...args: Nully<number>[]) =>
    args.reduce<number>((accum, cur) => (cur ?? 0) + accum, 0),
}

const product: FunctionDefinition = {
  name: "Product",
  async: false,
  args: [
    {
      nullable: true,
      dataType: EnumT.DataType.number,
      variadric: true,
    },
  ],
  dataType: EnumT.DataType.number,
  formatSpec: { type: EnumT.FormatSpecType.none },
  handler: (...args: Nully<number>[]) =>
    args.reduce<number>((accum, cur) => (cur ?? 0) * accum, 0),
}

const travelTime: FunctionDefinition = {
  name: "Commute Time",
  async: true,
  args: [
    {
      nullable: false,
      dataType: EnumT.DataType.numberArray,
      formatSpec: { type: EnumT.FormatSpecType.geographicCoordinates },
      label: "Starting Location",
      fieldHint: EnumT.Field.location,
    },
    {
      nullable: false,
      dataType: EnumT.DataType.numberArray,
      formatSpec: { type: EnumT.FormatSpecType.geographicCoordinates },
      label: "Ending Location",
      variableName: "Commute Destination",
      variableHint: "(such as your work address)",
    },
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      label: "Travel Mode",
      fixedList: [
        { label: "Walking", value: "walking" },
        { label: "Transit", value: "transit" },
        { label: "Bicycling", value: "bicycling" },
        { label: "Driving", value: "driving" },
      ],
    },
  ],
  dataType: EnumT.DataType.number,
  formatSpec: {
    type: EnumT.FormatSpecType.duration,
    minimumUnits: EnumT.DurationUnits.minutes,
  },
}

const airbnbPrice: FunctionDefinition = {
  name: "AirBnB Total Price",
  async: true,
  args: [
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      formatSpec: { type: EnumT.FormatSpecType.none },
      label: "Airbnb Room ID",
      fieldHint: EnumT.Field.externalId,
      hidden: true,
    },
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      formatSpec: { type: EnumT.FormatSpecType.none },
      label: "Source",
      fieldHint: EnumT.Field.source,
      hidden: true,
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      formatSpec: { type: EnumT.FormatSpecType.datetime, includeTime: false },
      label: "Check In",
      variableName: "Check In",
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      formatSpec: { type: EnumT.FormatSpecType.datetime, includeTime: false },
      label: "Check Out",
      variableName: "Check Out",
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      formatSpec: { type: EnumT.FormatSpecType.none },
      label: "Number of Guests",
      variableName: "Number of Guests",
    },
  ],
  dataType: EnumT.DataType.number,
  formatSpec: {
    type: EnumT.FormatSpecType.currency,
    currencyCode: CurrencyCode.USD,
  },
}

const hostelworldPrice: FunctionDefinition = {
  name: "Hostelworld Total Price",
  async: true,
  args: [
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      formatSpec: { type: EnumT.FormatSpecType.none },
      label: "Hostelworld Property ID",
      fieldHint: EnumT.Field.externalId,
      hidden: true,
    },
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      formatSpec: { type: EnumT.FormatSpecType.none },
      label: "Source",
      fieldHint: EnumT.Field.source,
      hidden: true,
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      formatSpec: { type: EnumT.FormatSpecType.datetime, includeTime: false },
      label: "Check In",
      variableName: "Check In",
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      formatSpec: { type: EnumT.FormatSpecType.datetime, includeTime: false },
      label: "Check Out",
      variableName: "Check Out",
    },
    {
      nullable: false,
      dataType: EnumT.DataType.number,
      formatSpec: { type: EnumT.FormatSpecType.none },
      label: "Number of Guests",
      variableName: "Number of Guests",
    },
    {
      nullable: false,
      dataType: EnumT.DataType.text,
      label: "Room Type Preference",
      fixedList: roomTypePreferences,
    },
    {
      nullable: true,
      dataType: EnumT.DataType.text,
      label: "Dorm Gender Preference",
      fixedList: dormGenderPreferences,
    },
    {
      nullable: true,
      dataType: EnumT.DataType.boolean,
      label: "Free cancellation only",
      variableName: "Free cancellation only",
      formatSpec: { type: EnumT.FormatSpecType.checkbox },
    },
    {
      nullable: true,
      dataType: EnumT.DataType.boolean,
      label: "Breakfast included (if available)",
      variableName: "Breakfast included (if available)",
      formatSpec: { type: EnumT.FormatSpecType.checkbox },
    },
  ],
  dataType: EnumT.DataType.number,
  formatSpec: {
    type: EnumT.FormatSpecType.currency,
    currencyCode: CurrencyCode.USD,
  },
}

export const equals: FunctionDefinition = {
  name: "Equals",
  async: false,
  args: [
    {
      nullable: true,
      dataType: EnumT.DataType.number,
      variadric: true,
    },
  ],
  dataType: EnumT.DataType.boolean,
  formatSpec: {
    type: EnumT.FormatSpecType.checkbox,
  },
  handler: (...args: (GenericDataValue | undefined)[]) => {
    if (args.length === 0) {
      return true
    }
    const arg1 = args[0]
    return args.every((arg) => arg === arg1)
  },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const builtins: PartialRecord<string, FunctionDefinition> = {
  sum,
  product,
  travelTime,
  airbnbPrice,
  hostelworldPrice,
  equals,
}
