import { EnumT } from "../schema/index.ts"
import type {
  NodeBase,
  ProcessedSheetContent,
  ReferenceBase,
} from "../types.ts"
import {
  assertDefined,
  newDefaultDict,
  newTypedArray,
  newTypedObject,
} from "../util/index.ts"
import { builtins } from "./functions.ts"
import {
  type ParsedItem,
  ParsedItemType,
  type ParsedReference,
} from "./parsing.ts"
import { cachedParseScript } from "./script.ts"

export const getAllReferencesRecursive = (
  parsedItem: ParsedItem
): ParsedReference[] => {
  switch (parsedItem.type) {
    case ParsedItemType.list:
      return parsedItem.items.flatMap(getAllReferencesRecursive)
    case ParsedItemType.funcCall:
      return parsedItem.args.flatMap(getAllReferencesRecursive)
    case ParsedItemType.reference:
      return [parsedItem]
  }
  return []
}

export const containsAsyncFunctionCall = (parsedItem: ParsedItem): boolean => {
  switch (parsedItem.type) {
    case ParsedItemType.list:
      return parsedItem.items.some(containsAsyncFunctionCall)
    case ParsedItemType.funcCall:
      return (
        assertDefined(builtins[parsedItem.funcName]).async ||
        parsedItem.args.some(containsAsyncFunctionCall)
      )
  }
  return false
}

interface Node extends NodeBase<string> {
  parsedScript: ParsedItem
}
interface NodeReference extends ReferenceBase<string> {}

export const buildGeneratorSpecGraph = ({
  columns,
  columnDefinitions,
}: {
  columns: ProcessedSheetContent["columns"]
  columnDefinitions: ProcessedSheetContent["columnDefinitions"]
}) => {
  const nodes = newTypedObject<string, Node>()
  const edges = newDefaultDict<string, NodeReference[]>(newTypedArray)
  for (const col of columns) {
    for (const colDef of columnDefinitions) {
      if (
        colDef.generatorSpec &&
        colDef.generatorSpec !== "" &&
        colDef.id === col.columnDefinitionId
      ) {
        const parsedScript = cachedParseScript(colDef.generatorSpec)
        const node: Node = {
          id: col.id,
          parsedScript,
        }
        nodes[node.id] = node
        getAllReferencesRecursive(parsedScript)
          .filter(
            (reference) =>
              reference.namespace === EnumT.ReferenceNamespace.columns
          )
          .forEach((reference) =>
            edges[node.id].push({
              to: reference.identifier,
            })
          )
      }
    }
  }
  return { nodes, edges }
}

export type LispableSpec = string
export type ParsedLispableSpec = ParsedItem
