<script lang="ts">
  import { eed } from "@/util"
  import { faTrash } from "@fortawesome/free-solid-svg-icons"
  import { builtins } from "@shared/lispable/functions.ts"
  import {
    type ParsedItem,
    ParsedItemType,
    assertParsedItemType,
  } from "@shared/lispable/parsing"
  import type { ProcessedSheetContent } from "@shared/types.ts"
  import { createEventDispatcher } from "svelte"
  import Button from "../Button.svelte"
  import FunctionArgumentControl from "./FunctionArgumentControl.svelte"
  import FunctionSelect from "./FunctionSelect.svelte"
  import type { OpenAddVariableModal } from "./types.ts"

  export let item: ParsedItem
  export let sheetContent: ProcessedSheetContent
  export let columnId: string | undefined
  export let openAddVariableModal: OpenAddVariableModal | undefined

  const dispatch = createEventDispatcher<{ change: ParsedItem }>()

  const changeFunc = (newName: string) => {
    dispatch("change", {
      type: ParsedItemType.funcCall,
      funcName: newName,
      args: [],
    })
  }
  const changeArg = (index: number) => (arg: ParsedItem) => {
    assertParsedItemType(item, ParsedItemType.funcCall)
    const newArgs = [...item.args]
    newArgs[index] = arg
    dispatch("change", { ...item, args: newArgs })
  }
  const deleteArg = (index: number) => () => {
    assertParsedItemType(item, ParsedItemType.funcCall)
    const newArgs = [...item.args]
    newArgs.splice(index, 1)
    dispatch("change", { ...item, args: newArgs })
  }
</script>

{#if item.type === ParsedItemType.funcCall}
  {@const builtin = builtins[item.funcName]}
  <div>
    <FunctionSelect value={item.funcName} on:change={eed(changeFunc)} />
    {#if builtin}
      {#each builtin.args as funcArg, i}
        {#if i <= item.args.length}
          {#if !funcArg.variadric}
            <div class="arg-wrapper">
              <div class="arg-label">
                {funcArg.label ?? `Parameter ${i + 1}`}
              </div>
              <FunctionArgumentControl
                value={item.args[i]}
                onChange={changeArg(i)}
                {openAddVariableModal}
                {columnId}
                {sheetContent}
                {funcArg}
              />
            </div>
          {:else}
            {#each [...item.args.slice(i), undefined] as arg, j}
              <div class="arg-wrapper">
                <div class="arg-label">
                  {`Parameter ${j + 1}`}{#if j + i !== item.args.length}<Button
                      on:click={deleteArg(j + i)}
                      variant="danger"
                      iconLeft={faTrash}
                    />{/if}
                </div>
                <FunctionArgumentControl
                  value={arg}
                  onChange={changeArg(j + i)}
                  {openAddVariableModal}
                  {columnId}
                  {sheetContent}
                  {funcArg}
                />
              </div>
            {/each}
          {/if}
        {/if}
      {/each}
    {/if}
  </div>
{/if}

<style>
  .arg-wrapper {
    margin-top: 10px;
    box-shadow: inset 0 0 0 2px var(--primary-fg);
    border-radius: 8px;
    padding: 10px;
  }
  .arg-label {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>
