<script lang="ts">
  import EditText from "./EditText.svelte"
  import EditBoolean from "./EditBoolean.svelte"
  import EditNumeric from "./EditNumeric.svelte"
  import type { DataFormatEvent, DataFormatOverrides } from "./types"
  import type { SvelteComponent } from "svelte"
  import { EnumT } from "@shared/schema"
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import EditNumericArray from "./EditNumericArray.svelte"
  import EditTextArray from "./EditTextArray.svelte"

  type T = $$Generic<EnumT.DataType>
  export let dataType: T
  export let value: GenericDataValue<T> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides = {}
  export let autofocus: boolean = false
  export let clearable: boolean = false
  export let disabled: boolean = false
  export let onChange:
    | ((updatedValue: GenericDataValue<T> | undefined) => void)
    | undefined = undefined

  let internalValue = value
  let initialChangeDone = false
  const setInitialChange = (done: boolean) => (initialChangeDone = done)
  const setInternalValue = (v: GenericDataValue<T> | undefined) =>
    (internalValue = v)

  $: {
    if (!initialChangeDone) {
      setInitialChange(true)
    } else {
      if (onChange) {
        onChange(internalValue)
      } else {
        value = internalValue
      }
    }
  }

  $: setInternalValue(value)

  let innerComponent: RendererComponentT<EnumT.DataType> | undefined = undefined
  export const emitEvent = (args: DataFormatEvent) =>
    innerComponent?.emitEvent?.(args)

  type RendererProps<DT extends EnumT.DataType> = {
    formatSpec: FormatSpec
    value: GenericDataValue<DT> | undefined
    dataFormatOverrides: DataFormatOverrides
    autofocus?: boolean
    clearable?: boolean
    disabled?: boolean
  }
  type RendererComponent<DT extends EnumT.DataType> = typeof SvelteComponent<
    RendererProps<DT>
  >
  type RendererComponentT<DT extends EnumT.DataType> = SvelteComponent<
    RendererProps<DT>
  >

  type RenderInfo<DT extends EnumT.DataType = EnumT.DataType> = {
    renderer: RendererComponent<DT>
  }

  $: renderInfo = ((): RenderInfo => {
    switch (dataType as EnumT.DataType) {
      case EnumT.DataType.number:
        return {
          renderer: EditNumeric as RendererComponent<EnumT.DataType.number>,
        }
      case EnumT.DataType.text:
        return {
          renderer: EditText as RendererComponent<EnumT.DataType.text>,
        }
      case EnumT.DataType.boolean:
        return {
          renderer: EditBoolean as RendererComponent<EnumT.DataType.boolean>,
        }
      case EnumT.DataType.textArray:
        return {
          renderer: EditTextArray as RendererComponent<EnumT.DataType.text>,
        }
      case EnumT.DataType.numberArray:
        return {
          renderer:
            EditNumericArray as RendererComponent<EnumT.DataType.numberArray>,
        }
    }
  })()
</script>

<svelte:component
  this={renderInfo.renderer}
  bind:value={internalValue}
  bind:this={innerComponent}
  {formatSpec}
  {dataFormatOverrides}
  {autofocus}
  {clearable}
  {disabled}
/>
