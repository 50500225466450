import { derived, readable, writable } from "svelte/store"
import type {
  CachedFuncCall,
  FunctionResolverWithContext,
  GenericDataValue,
  ParsedSheetContent,
  ProcessedSheetContent,
  SortInfo,
} from "@shared/types.ts"
import {
  newTypedObject,
  coalesceEmptyArray,
  stringifyJSONKey,
  objectKeys,
  assertDefined,
} from "@shared/util/index.ts"
import {
  processSheetContent,
  getParsedValueByField,
  parseSheetContent,
  getColumnDefinitionForCol,
} from "@shared/sheet"
import {
  findDefaultTitleColumn,
  findDefaultLocationColumn,
  getItem,
  LocalStorageKey,
  setItem,
  getCellValueForColumn,
  logException,
  isDev,
} from "@/util"
import { EnumT } from "@shared/schema/index.ts"
import { SortDirection } from "@/lib/DataTable/types"
import type { PartialRecord } from "@/global"
import { cachedFunctionResolver } from "@/api"
import { isAccessTypeGranted } from "@shared/permissions/index.ts"
import { DateTime } from "luxon"
import { getSocket } from "@/client/socket"
import { currentUserAccountId } from "./authStore"
import { filterProcessedSheetContent } from "@shared/sheet/filterSheetContent"

type ActiveViewData = {
  sheetViewId: string
  userAccountId: string
}[]

type RootState = {
  connected: boolean
  sheetsData: PartialRecord<string, ParsedSheetContent>
  accessTypeData: PartialRecord<string, EnumT.AccessType>
  activeViewData: PartialRecord<string, ActiveViewData>
  sortCol: PartialRecord<string, string>
  sortDirection: PartialRecord<string, SortDirection>
  activeSheetId: string | null
  cachedFuncCalls: PartialRecord<string, GenericDataValue>
}

const rootStoreFactory = () => {
  const { subscribe, update } = writable<RootState>({
    connected: false,
    sheetsData: newTypedObject<string, ParsedSheetContent>(),
    accessTypeData: newTypedObject<string, EnumT.AccessType>(),
    activeViewData: newTypedObject<string, ActiveViewData>(),
    sortCol: newTypedObject<string, string>(),
    sortDirection: newTypedObject<string, SortDirection>(),
    activeSheetId: null as null | string,
    cachedFuncCalls: newTypedObject<string, GenericDataValue>(),
  })

  getSocket()
    .then((socket) => {
      update((store) => ({ ...store, connected: socket.connected }))

      socket.on("connect", () =>
        update((store) => ({ ...store, connected: true }))
      )

      socket.on("disconnect", () =>
        update((store) => ({ ...store, connected: false }))
      )

      socket.on("updateSheetContent", ({ content }) => {
        update((store) => ({
          ...store,
          sheetsData: {
            ...store.sheetsData,
            [content.sheet.id]: parseSheetContent(content),
          },
          sortCol: {
            ...store.sortCol,
            [content.sheet.id]:
              getItem(LocalStorageKey.sortingOnColId, content.sheet.id) ??
              undefined,
          },
          sortDirection: {
            ...store.sortDirection,
            [content.sheet.id]:
              getItem(LocalStorageKey.sortDirection, content.sheet.id) ??
              undefined,
          },
        }))
      })

      socket.on("updateAccessType", ({ accessType, sheetId }) =>
        update((store) => ({
          ...store,
          accessTypeData: {
            ...store.accessTypeData,
            [sheetId]: accessType,
          },
        }))
      )

      socket.on("updateUserActiveViews", ({ sheetId, activeViews }) =>
        update((store) => ({
          ...store,
          activeViewData: { ...store.activeViewData, [sheetId]: activeViews },
        }))
      )
    })
    .catch((err: unknown) => logException(err, "registering rootStore socket"))

  return {
    subscribe,
    update,
  }
}

let rootStoreSaved: ReturnType<typeof rootStoreFactory> | null = null

export const rootStore = () => {
  if (!rootStoreSaved) {
    rootStoreSaved = rootStoreFactory()
  }
  return rootStoreSaved
}

export const activeSheetName = derived(rootStore(), ($store) =>
  $store.activeSheetId
    ? $store.sheetsData[$store.activeSheetId]?.sheet.name
    : undefined
)

export const storeConnected = derived(rootStore(), ($store) => $store.connected)

const getProcessedSheetContent = ({
  $store,
  sheetId,
}: {
  $store: RootState
  sheetId: string
}) => {
  const miliStart = DateTime.now().toMillis()
  const cachedFuncCalls = $store.cachedFuncCalls
  const sheetContent = $store.sheetsData[sheetId]
  const sortingOnColId = $store.sortCol[sheetId]
  const sortDirection = $store.sortDirection[sheetId]
  const sortInfo: SortInfo | undefined = sortingOnColId
    ? { sortingOnColId, sortDirection: sortDirection ?? SortDirection.asc }
    : undefined

  if (!sheetContent) {
    return undefined
  }

  const resolveFunctionWithContext: FunctionResolverWithContext = ({
    funcCall,
  }) => {
    const serializedFuncCall = stringifyJSONKey(funcCall)
    if (serializedFuncCall in cachedFuncCalls) {
      return cachedFuncCalls[serializedFuncCall]
    }

    cachedFunctionResolver.push(serializedFuncCall)

    return undefined
  }

  const activeView = selectActiveView($store, sheetId)
  const filters = activeView?.filters ?? undefined
  const processed = processSheetContent({
    sheetContent,
    sortInfo,
    resolveFunctionWithContext,
    filters,
  })

  void cachedFunctionResolver.flush(receivedCachedFuncCalls)

  if (isDev()) {
    console.log(
      "processed sheet content in ",
      DateTime.now().toMillis() - miliStart,
      "millis"
    )
  }
  return processed
}

const processedSheetContentLookup = derived(rootStore(), ($store) => {
  const sheetContentLookup = newTypedObject<string, ProcessedSheetContent>()
  for (const sheetId of objectKeys($store.sheetsData)) {
    sheetContentLookup[sheetId] = getProcessedSheetContent({ sheetId, $store })
  }
  return sheetContentLookup
})

export const sheetContentFactory = derived(
  processedSheetContentLookup,
  (sheetContentLookup) => (sheetId: string) => sheetContentLookup[sheetId]
)
export const filteredSheetContentFactory = derived(
  processedSheetContentLookup,
  (sheetContentLookup) => (sheetId: string) =>
    sheetContentLookup[sheetId]
      ? filterProcessedSheetContent(assertDefined(sheetContentLookup[sheetId]))
      : undefined
)
export const sheetAccessFactory = (sheetId: string) =>
  derived(rootStore(), ($store) => $store.accessTypeData[sheetId])

let myUserId: string | undefined = undefined
currentUserAccountId.subscribe((newId) => (myUserId = newId))

const selectActiveView = ($store: RootState, sheetId: string) => {
  const sheetViewId = $store.activeViewData[sheetId]?.find(
    (activeViewData) => activeViewData.userAccountId === myUserId
  )?.sheetViewId

  if (sheetViewId == null) {
    return undefined
  }

  return $store.sheetsData[sheetId]?.views.find(
    (view) => view.id === sheetViewId
  )
}

export const myActiveViewFactory = derived(
  rootStore(),
  ($store) => (sheetId: string) => selectActiveView($store, sheetId)
)

export const sortingOnColIdFactory = (sheetId: string) =>
  derived(rootStore(), ($store) => $store.sortCol[sheetId])
export const sortDirectionFactory = (sheetId: string) =>
  derived(rootStore(), ($store) => $store.sortDirection[sheetId])

export const setSortingOnColId = ({
  sheetId,
  sortingOnColId,
}: {
  sheetId: string
  sortingOnColId: string
}) => {
  setItem(LocalStorageKey.sortingOnColId, sortingOnColId, sheetId)
  rootStore().update((store) => ({
    ...store,
    sortCol: {
      ...store.sortCol,
      [sheetId]: sortingOnColId,
    },
  }))
}

export const receivedCachedFuncCalls = ({
  cachedFuncCalls,
}: {
  cachedFuncCalls: CachedFuncCall[]
}) =>
  rootStore().update((store) => ({
    ...store,
    cachedFuncCalls: {
      ...store.cachedFuncCalls,
      ...Object.fromEntries(
        cachedFuncCalls.map((funcCall) => [
          stringifyJSONKey(funcCall.serializedFuncCall),
          funcCall.value ?? undefined,
        ])
      ),
    },
  }))

export const setSortDirection = ({
  sheetId,
  sortDirection,
}: {
  sheetId: string
  sortDirection: SortDirection
}) => {
  setItem(LocalStorageKey.sortDirection, sortDirection, sheetId)
  rootStore().update((store) => ({
    ...store,
    sortDirection: {
      ...store.sortDirection,
      [sheetId]: sortDirection,
    },
  }))
}

export const defaultTitleColumnFactory = (sheetId: string) =>
  derived(rootStore(), ($store) =>
    findDefaultTitleColumn(
      coalesceEmptyArray($store.sheetsData[sheetId]?.columns),
      coalesceEmptyArray($store.sheetsData[sheetId]?.columnDefinitions)
    )
  )
export const defaultLocationColumnFactory = (sheetId: string) =>
  derived(rootStore(), ($store) =>
    findDefaultLocationColumn(
      coalesceEmptyArray($store.sheetsData[sheetId]?.columns),
      coalesceEmptyArray($store.sheetsData[sheetId]?.columnDefinitions)
    )
  )

export const activeSheetId = derived(
  rootStore(),
  ($store) => $store.activeSheetId
)

export const columnHeaderDataFactory = ({
  sheetId,
  columnId,
}: {
  sheetId: string
  columnId: string
}) =>
  derived(
    [
      sheetContentFactory,
      sortingOnColIdFactory(sheetId),
      sortDirectionFactory(sheetId),
    ],
    ([$sheetContentFactory, sortingOnColId, sortDirection]) => {
      const sheetContent = $sheetContentFactory(sheetId)
      if (!sheetContent) {
        return undefined
      }
      const column = sheetContent.columns.find((col) => col.id === columnId)
      const colDef = getColumnDefinitionForCol({
        sheetContent,
        colId: columnId,
      })
      if (!column || !colDef) {
        return undefined
      }
      return {
        title: column.name,
        sortDirection: sortingOnColId === columnId ? sortDirection : undefined,
      }
    }
  )

export const cellDataFactory = derived(
  sheetContentFactory,
  ($sheetContentFactory) =>
    ({
      sheetId,
      columnId,
      rowId,
    }: {
      sheetId: string
      columnId: string
      rowId: string
    }) => {
      const cellKey = { colId: columnId, rowId }
      const sheetContent = $sheetContentFactory(sheetId)
      if (!sheetContent) {
        return undefined
      }
      const cellValue = getCellValueForColumn({ sheetContent, cellKey })
      const colDef = getColumnDefinitionForCol({
        sheetContent,
        colId: columnId,
      })
      return {
        cellValue,
        colDef,
      }
    }
)

export const hasSheetAccessTypeFactory = ({
  sheetId,
  accessType: requestedAccessType,
}: {
  sheetId: string | undefined
  accessType: EnumT.AccessType
}) =>
  sheetId
    ? derived(sheetAccessFactory(sheetId), (actualAccessType) =>
        actualAccessType
          ? isAccessTypeGranted({ actualAccessType, requestedAccessType })
          : undefined
      )
    : readable(undefined)

export const hasWriteAccess = ({ sheetId }: { sheetId: string | undefined }) =>
  hasSheetAccessTypeFactory({ sheetId, accessType: EnumT.AccessType.owner })

export const fieldValueFactory = ({
  sheetId,
  field,
  rowId,
}: {
  sheetId: string
  field: EnumT.Field
  rowId: string
}) =>
  derived(sheetContentFactory, ($sheetContentFactory) => {
    const sheetContent = $sheetContentFactory(sheetId)
    if (!sheetContent || !sheetContent.rows.some(({ id }) => id === rowId)) {
      return undefined
    }
    return getParsedValueByField({ sheetContent, field, rowId })
  })
