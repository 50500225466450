import z from "zod"
import { ZSheetInfo } from "./sheet"
import { EnumT } from "../schema"
import { ZLocalUserActivity } from "./user"
import { ZExtractedItem } from "./input"
import { ZProxyConfig } from "./source"
import {
  ZCachedFuncCall,
  ZFormatSpec,
  ZFunctionCall,
  ZGenericDataValue,
} from "../types"

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
}

export type AllowedSecurities = "open" | "anon" | "linked" | "admin"

export type ApiEndpoint<AT, RT, ST extends AllowedSecurities> = {
  endpointPath: string
  method: HttpMethod
  argSchema: z.ZodSchema<AT>
  responseSchema: z.ZodSchema<RT>
  minimumSecurity: ST
}

export type ApiArgumentT<
  ET extends ApiEndpoint<unknown, unknown, AllowedSecurities>
> = ET extends ApiEndpoint<infer T, unknown, AllowedSecurities> ? T : never
export type ApiResponseT<
  ET extends ApiEndpoint<unknown, unknown, AllowedSecurities>
> = ET extends ApiEndpoint<unknown, infer T, AllowedSecurities> ? T : never
export type ApiMinimumSecurityT<
  ET extends ApiEndpoint<unknown, unknown, AllowedSecurities>
> = ET extends ApiEndpoint<unknown, unknown, infer T> ? T : never

export type ApiArgumentFromKeyT<KT extends keyof API_ROUTES_T> = ApiArgumentT<
  API_ROUTES_T[KT]
>
export type ApiResponseFromKeyT<KT extends keyof API_ROUTES_T> = ApiResponseT<
  API_ROUTES_T[KT]
>
export type ApiMinimumSecurityFromKeyT<KT extends keyof API_ROUTES_T> =
  ApiMinimumSecurityT<API_ROUTES_T[KT]>

const apiEndpoint = <AT, RT, ST extends AllowedSecurities>(
  args: ApiEndpoint<AT, RT, ST>
) => args

export const API_ROUTES = {
  getUserSheets: apiEndpoint({
    endpointPath: "/sheets",
    method: HttpMethod.GET,
    argSchema: z.null(),
    responseSchema: z.object({ sheets: z.array(ZSheetInfo) }),
    minimumSecurity: "anon",
  }),
  createSheet: apiEndpoint({
    endpointPath: "/sheets",
    method: HttpMethod.POST,
    argSchema: z.object({
      listingType: z.nativeEnum(EnumT.ListingType).nullable(),
      name: z.string().optional(),
    }),
    responseSchema: z.object({
      sheetId: z.string().uuid(),
    }),
    minimumSecurity: "anon",
  }),
  checkLocalUser: apiEndpoint({
    endpointPath: "/user/localActivity",
    method: HttpMethod.GET,
    argSchema: z.null(),
    responseSchema: z.object({ activity: z.array(ZLocalUserActivity) }),
    minimumSecurity: "linked",
  }),
  beginBrowserAuth: apiEndpoint({
    endpointPath: "/auth/begin",
    method: HttpMethod.POST,
    argSchema: z.null(),
    responseSchema: z.object({ authUrl: z.string() }),
    minimumSecurity: "open",
  }),
  completeBrowserAuth: apiEndpoint({
    endpointPath: "/auth/complete",
    method: HttpMethod.POST,
    argSchema: z.object({ code: z.string() }),
    responseSchema: z.object({
      success: z.boolean(),
      newlyCreated: z.boolean(),
    }),
    minimumSecurity: "anon",
  }),
  beginBrowserUnauth: apiEndpoint({
    endpointPath: "/auth/end",
    method: HttpMethod.POST,
    argSchema: z.null(),
    responseSchema: z.object({ unauthUrl: z.string() }),
    minimumSecurity: "anon",
  }),
  getOrAddUser: apiEndpoint({
    endpointPath: "/auth/get_or_add_user",
    method: HttpMethod.POST,
    argSchema: z.null(),
    responseSchema: z.object({
      anon: z.boolean(),
      userType: z.optional(z.nativeEnum(EnumT.UserType)),
      numSheets: z.optional(z.number()),
      userAccountId: z.string().uuid(),
    }),
    minimumSecurity: "open",
  }),
  mergeLocalUser: apiEndpoint({
    endpointPath: "/auth/merge",
    method: HttpMethod.POST,
    argSchema: z.null(),
    responseSchema: z.null(),
    minimumSecurity: "linked",
  }),
  checkInput: apiEndpoint({
    endpointPath: "/input/check",
    method: HttpMethod.POST,
    argSchema: z.object({ input: z.string() }),
    responseSchema: z.object({
      data: z.union([
        z.literal(false),
        z.object({
          source: z.nativeEnum(EnumT.Source),
          response: z.object({
            isValid: z.boolean(),
            message: z.optional(z.string()),
          }),
        }),
      ]),
    }),
    minimumSecurity: "open",
  }),
  fetchInput: apiEndpoint({
    endpointPath: "/input/fetch",
    method: HttpMethod.POST,
    argSchema: z.object({ input: z.string() }),
    responseSchema: z.object({ extractedItems: z.array(ZExtractedItem) }),
    minimumSecurity: "open",
  }),
  setSourceProxySettings: apiEndpoint({
    endpointPath: "/sources/proxySettings",
    method: HttpMethod.POST,
    argSchema: z.object({
      source: z.nativeEnum(EnumT.Source),
      config: ZProxyConfig,
    }),
    responseSchema: z.null(),
    minimumSecurity: "admin",
  }),
  getSourceProxySettings: apiEndpoint({
    endpointPath: "/sources/proxySettings",
    method: HttpMethod.GET,
    argSchema: z.object({ source: z.optional(z.nativeEnum(EnumT.Source)) }),
    responseSchema: z.record(z.nativeEnum(EnumT.Source), ZProxyConfig),
    minimumSecurity: "admin",
  }),
  getCachedFuncCalls: apiEndpoint({
    endpointPath: "/functions/get_cached_func_calls",
    method: HttpMethod.GET,
    argSchema: z.object({ funcCalls: z.array(ZFunctionCall) }),
    responseSchema: z.object({ cachedFuncCalls: z.array(ZCachedFuncCall) }),
    minimumSecurity: "anon",
  }),
  addSheetGlobal: apiEndpoint({
    endpointPath: "/sheet_globals",
    method: HttpMethod.POST,
    argSchema: z.object({
      sheetId: z.string().uuid(),
      varName: z.string(),
      dataType: z.nativeEnum(EnumT.DataType),
      formatSpec: z.optional(ZFormatSpec),
      value: z.optional(ZGenericDataValue),
    }),
    responseSchema: z.object({ id: z.string().uuid() }),
    minimumSecurity: "anon",
  }),
}

export type API_ROUTES_T = typeof API_ROUTES
