<script lang="ts">
  import RouterView, { navigateTo } from "@/lib/RouterView"
  import NotFound from "../NotFound.svelte"
  import TableView from "./TableView.svelte"
  import {
    rootStore,
    activeSheetName,
    hasWriteAccess,
    storeConnected,
  } from "src/stores/sheetStore"
  import RouterLink from "@/lib/RouterView/RouterLink.svelte"
  import { dashboardRoute, listRoute, mapRoute, tableRoute } from "@/routes"
  import Editable from "@/lib/Editable.svelte"
  import Button from "@/lib/Button.svelte"
  import { curPath as curPathStore } from "../../lib/RouterView/store.ts"
  import {
    faChevronDown,
    faChevronUp,
    faList,
    faMap,
    faPlus,
    faShareAlt,
    faTable,
  } from "@fortawesome/free-solid-svg-icons"
  import { activeSheetId, sheetContentFactory } from "@/stores/sheetStore"
  import { pickRoute } from "@/lib/RouterView/util"
  import {
    beginAuth,
    beginUnauth,
    keyPressWrapper,
    someRowHasLocation,
  } from "@/util"
  import { didInitialLoad, signedIn } from "@/stores/authStore"
  import TableToolbar from "./TableToolbar.svelte"
  import type { Route } from "@/lib/RouterView/types"
  import ListView from "./ListView.svelte"
  import Icon from "@/lib/Icon.svelte"
  import FloatingActionButton from "@/lib/FloatingActionButton.svelte"
  import {
    assertDefined,
    coalesceEmptyArray,
    newTypedObject,
  } from "@shared/util/index.ts"
  import type { ExtractorKey } from "@shared/types.ts"
  import type { SheetInfo } from "@shared/api/sheet.ts"
  import SheetCard from "../Dashboard/SheetCard.svelte"
  import { onMount } from "svelte"
  import { dataViewModule } from "./module.ts"
  import SidepanelManager from "@/lib/sidepanelManager/SidepanelManager.svelte"
  import ModalManager from "@/lib/modalManager/ModalManager.svelte"
  import FixedLayoutVertical from "@/lib/layout/FixedLayoutVertical.svelte"
  import { globalEditAccessModal } from "@/lib/modals/index.ts"
  import { Shareable } from "@/lib/share/data.ts"
  import type { EnumT } from "@shared/schema/index.ts"
  import { apiClient } from "@/api/index.ts"
  import { getSocket } from "@/client/socket.ts"
  import NothingHereYet from "./NothingHereYet.svelte"

  export let matches: [string, string]
  export let query: URLSearchParams | null

  const EXPANDED_BOTTOM_BAR_HEIGHT = 300

  let name = ""
  let pageResult: ReturnType<typeof pickRoute> = null
  let currentTouches = newTypedObject<string, number>()
  let diffY = 0
  let startedExpanded: boolean = false
  let sheets: SheetInfo[] | undefined = undefined

  $: itemId = query?.get("itemId") ?? undefined
  $: sheetId = matches[1]
  $: rootStore().update((store) => ({ ...store, activeSheetId: sheetId }))
  $: sheetContent = $activeSheetId ? $sheetContentFactory($activeSheetId) : null
  $: if ($storeConnected) {
    getSocket().then((socket) => socket.emit("joinSheet", { sheetId }))
  }
  $: hasWrite = hasWriteAccess({ sheetId })

  activeSheetName.subscribe((newVal) => (name = newVal ?? name))

  const openNewItemSidepanel = () => {
    dataViewModule.newItemSidepanel.open({
      props: {
        onAccept: async (extractorKeys: ExtractorKey[]) => {
          ;(await getSocket()).emit("newInput", {
            keys: extractorKeys,
            sheetId,
          })
          dataViewModule.newItemSidepanel.close()
        },
        listingType: sheetContent?.sheet.listingType,
      },
    })
  }

  const renameSheet = async (name: CustomEvent<string>) => {
    ;(await getSocket()).emit("renameSheet", { name: name.detail, sheetId })
  }

  $: hasLocation = sheetContent && someRowHasLocation({ sheetContent })

  $: routes = [
    {
      pattern:
        /^\/d\/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})\/table$/,
      mainViewComponent: TableView,
      toolbarViewComponent: TableToolbar,
      id: "table",
    },
    {
      pattern:
        /^\/d\/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})\/list$/,
      mainViewComponent: ListView,
      toolbarViewComponent: null,
      id: "list",
    },
    {
      pattern:
        /^\/d\/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})\/map/,
      mainViewDynamicComponent: () => import("./MapView.svelte"),
      toolbarViewComponent: null,
      id: "map",
    },
    {
      pattern:
        /^\/d\/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})\/chart$/,
      mainViewDynamicComponent: () => import("./ChartView.svelte"),
      toolbarViewComponent: null,
      id: "chart",
    },
    {
      pattern: /^/,
      mainViewComponent: NotFound,
      toolbarViewComponent: null,
    },
  ]

  $: toolbarRoutes = routes.map(({ pattern, toolbarViewComponent, id }) => ({
    pattern,
    id,
    component: toolbarViewComponent,
    dynamicComponent: undefined,
  }))
  $: mainRoutes = routes.map(
    ({ pattern, mainViewComponent, mainViewDynamicComponent, id }) =>
      ({
        pattern,
        id,
        component: mainViewComponent,
        dynamicComponent: mainViewDynamicComponent,
      } as Route)
  )

  $: {
    if ($curPathStore) {
      pageResult = pickRoute(mainRoutes)
    }
  }

  const refreshSheets = async () => {
    sheets = (await apiClient.call("getUserSheets", null)).sheets
  }

  const bottomBarTouchStart = (e: TouchEvent) => {
    for (const touch of Array.from(e.changedTouches)) {
      currentTouches[touch.identifier] = touch.pageY + diffY
      startedExpanded = diffY > (EXPANDED_BOTTOM_BAR_HEIGHT * 3) / 4
    }
  }

  const bottomBarTouchMove = (e: TouchEvent) => {
    for (const touch of Array.from(e.changedTouches)) {
      const startY = currentTouches[touch.identifier] ?? 0
      const curY = touch.pageY
      diffY = Math.min(Math.max(startY - curY, 0), EXPANDED_BOTTOM_BAR_HEIGHT)
    }
    e.preventDefault()
  }

  const bottomBarTouchEnd = (e: TouchEvent) => {
    for (const touch of Array.from(e.changedTouches)) {
      delete currentTouches[touch.identifier]
      if (startedExpanded) {
        if (diffY > (EXPANDED_BOTTOM_BAR_HEIGHT * 3) / 4) {
          diffY = EXPANDED_BOTTOM_BAR_HEIGHT
        } else {
          diffY = 0
        }
      } else {
        if (diffY > EXPANDED_BOTTOM_BAR_HEIGHT / 4) {
          diffY = EXPANDED_BOTTOM_BAR_HEIGHT
        } else {
          diffY = 0
        }
      }
    }
  }

  const toggleExpandBottomBar = () => {
    if (diffY < EXPANDED_BOTTOM_BAR_HEIGHT) {
      diffY = EXPANDED_BOTTOM_BAR_HEIGHT
    } else {
      diffY = 0
    }
  }

  onMount(() => refreshSheets())
  onMount(async () => await getSocket())

  $: context = {
    ...dataViewModule,
    openNewItemSidepanel,
  }

  const openEditAccessModal = () => {
    globalEditAccessModal.open({
      props: {
        accessType: assertDefined(sheetContent).sheet.defaultAccessType,
        editDisabled: !$hasWrite,
        shareData: Shareable.sheetLink({ itemId, sheetId }),
      },
      onAccept: async (accessType: EnumT.AccessType) => {
        ;(await getSocket()).emit("updateSheetDefaultAccessType", {
          sheetId,
          accessType,
        })
      },
    })
  }
</script>

<div class="app-wrapper">
  <FixedLayoutVertical flexBody>
    <div class="top-bar" slot="header">
      <div class="top-bar-title">
        <div class="top-bar-inner">
          <RouterLink to={dashboardRoute()}
            ><img class="logo" src="/logo.svg" alt="logo" /></RouterLink
          >
          <div class="top-bar-inner-right">
            <div class="name-wrapper">
              <Editable
                readonly={!$hasWrite}
                on:enter={renameSheet}
                bind:value={name}
              />
            </div>
          </div>
        </div>
        <div class="top-bar-title-right">
          {#if $didInitialLoad && !$signedIn}
            <div class="sign-in-btn-wrapper">
              <div />
              <div>
                <Button iconLeft={faShareAlt} on:click={openEditAccessModal} />
                <Button variant="success" on:click={beginAuth}>Sign In</Button>
              </div>
            </div>
          {:else if $didInitialLoad}
            <div>
              <Button iconLeft={faShareAlt} on:click={openEditAccessModal} />
              <Button on:click={beginUnauth}>Sign Out</Button>
            </div>
          {/if}
        </div>
      </div>
      <div class="toolbar-row">
        <RouterView routes={toolbarRoutes} {context} />
      </div>
    </div>
    {#if sheetContent?.rows.length === 0}
      <div class="no-data">
        <NothingHereYet sheetId={sheetContent.sheet.id} {context} />
        <FloatingActionButton
          disabled={!$hasWrite}
          icon={faPlus}
          label="Add new item"
          --offset-bottom="65px"
          on:click={openNewItemSidepanel}
        />
      </div>
    {:else if sheetContent}
      <RouterView routes={mainRoutes} {context} />
    {:else}
      <div class="loading" />
    {/if}
    <div
      class="bottom-nav-bar-wrapper"
      style={`margin-bottom: ${diffY}px`}
      on:touchstart={bottomBarTouchStart}
      on:touchend={bottomBarTouchEnd}
      on:touchmove={bottomBarTouchMove}
      on:touchcancel={bottomBarTouchEnd}
      slot="footer"
    >
      <button class="drag-up" on:click={toggleExpandBottomBar}>
        <Icon
          icon={diffY < EXPANDED_BOTTOM_BAR_HEIGHT / 4
            ? faChevronUp
            : faChevronDown}
        />
      </button>
      <div class="bottom-nav-bar">
        <div
          on:click={() => navigateTo(tableRoute({ sheetId }))}
          on:keypress={keyPressWrapper(() =>
            navigateTo(tableRoute({ sheetId }))
          )}
          role="button"
          tabindex="0"
          aria-label="Go to table view"
          class="bottom-nav-item"
          class:active={pageResult?.route.id === "table"}
        >
          <span class="bottom-nav-item-icon"><Icon icon={faTable} /></span><span
            class="bottom-nav-item-text">Table</span
          >
        </div>
        <div
          on:click={() => navigateTo(listRoute({ sheetId }))}
          on:keypress={keyPressWrapper(() =>
            navigateTo(listRoute({ sheetId }))
          )}
          role="button"
          tabindex="0"
          aria-label="Go to list view"
          class="bottom-nav-item"
          class:active={pageResult?.route.id === "list"}
        >
          <span class="bottom-nav-item-icon"><Icon icon={faList} /></span><span
            class="bottom-nav-item-text">List</span
          >
        </div>
        {#if hasLocation}
          <div
            on:click={() => navigateTo(mapRoute({ sheetId }))}
            on:keypress={keyPressWrapper(() =>
              navigateTo(mapRoute({ sheetId }))
            )}
            role="button"
            tabindex="0"
            aria-label="Go to map view"
            class="bottom-nav-item"
            class:active={pageResult?.route.id === "map"}
          >
            <span class="bottom-nav-item-icon"><Icon icon={faMap} /></span><span
              class="bottom-nav-item-text">Map</span
            >
          </div>
        {/if}
      </div>
      <div class="bottom-nav-bar-expanded" style={`height: ${diffY}px`}>
        <div
          on:click={toggleExpandBottomBar}
          on:touchstart|stopPropagation
          on:touchend|stopPropagation
          on:touchmove|stopPropagation
          on:touchcancel|stopPropagation
          class="bottom-nav-bar-expanded-inner"
        >
          {#each coalesceEmptyArray(sheets) as sheetInfo}
            <SheetCard {sheetInfo} />
          {/each}
        </div>
      </div>
    </div>
  </FixedLayoutVertical>
  <SidepanelManager sidepanelManager={dataViewModule.sidepanelManager} />
  <ModalManager modalManager={dataViewModule.modalManager} />
</div>

<style>
  .drag-up {
    position: absolute;
    left: auto;
    right: auto;
    top: -24px;
    width: 200px;
    font-size: 20px;
    padding-top: 4px;
    border-radius: var(--default-rounding);
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 32px;
    background-color: var(--secondary-bg);
    z-index: -1;
    border: none;
    color: var(--primary-fg);
    cursor: pointer;
    box-shadow: 0px -4px 8px 0px rgba(10, 10, 10, 0.45);
  }
  .bottom-nav-bar-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 1000;
    background: var(--secondary-bg);
    box-shadow: 0px -4px 8px 0px rgba(10, 10, 10, 0.45);
  }
  .bottom-nav-bar-expanded {
    left: auto;
    right: auto;
    position: absolute;
    width: 100%;
    top: 100%;
    background-color: var(--primary-bg);
    overflow: hidden;
  }
  .bottom-nav-bar-expanded-inner {
    max-height: 300px;
    overflow-y: auto;
    padding: 16px;
  }
  .bottom-nav-bar {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    background: var(--secondary-bg);
  }
  .bottom-nav-item {
    flex: 1;
    background: var(--secondary-bg);
    color: var(--secondary-fg);
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    font-weight: 800;
    text-transform: uppercase;
  }
  .bottom-nav-item:hover {
    filter: brightness(85%);
  }
  .bottom-nav-item.active {
    color: var(--action-alt);
  }
  .bottom-nav-item-icon {
    font-size: 15px;
  }
  @media only screen and (max-width: 769px) {
    .bottom-nav-item {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .bottom-nav-item-icon {
      font-size: 20px;
    }
    .bottom-nav-item-text {
      display: none;
    }
  }
  .sign-in-btn-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .app-wrapper {
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .top-bar-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .top-bar-inner {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 0px;
  }
  .name-wrapper {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "...";
  }
  .top-bar-inner-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    flex: 1;
    width: 0px;
  }
  .top-bar-title-right {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    padding: 5px;
  }
  .top-bar {
    z-index: 100;
    background-color: var(--primary-bg);
  }
  .toolbar-row {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    background: var(--primary-accent);
  }
  .no-data,
  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  .logo {
    height: 40px;
    aspect-ratio: 1/1;
    align-self: center;
    margin-left: 6px;
  }
</style>
